import React from 'react'
import '../CSS/loading.css'

export default function Loading() {
    
  return (
    <div className='flex justify-center items-center min-h-screen'>

    <div className="loadingio-spinner-bean-eater-ormraj174j"><div className="ldio-rww4qgfck3e">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
    </div>
  )
}
