import React from 'react'
import StoreRow from './StoreRow'
import { useSelector } from 'react-redux';

export default function StoresTable({handleActiveBtn,handleParentBtn}) {
    const stores = useSelector((state)=>state.stores.stores);

  return (
    <div className='table-container'>
        <table className='table text-center border border-slate-400'>
            <thead className='bg-slate-100'>
            <tr>
                <th>#</th>
                <th className='fixed'>Store Name</th>
                <th>BaseURL</th>
                <th>Identifier</th>
                <th>Display Parent Category</th>
                <th>Create On</th>
                <th>Active</th>
            </tr>
            </thead>
            <tbody>
                {stores.map((item,i)=>{
                return(
               <StoreRow key={item.id} item={item} i={i} handleActiveBtn={handleActiveBtn} handleParentBtn={handleParentBtn}/>
                )
                })}
            </tbody>
        </table>
        </div>
  )
}
