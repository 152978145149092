import { configureStore } from '@reduxjs/toolkit';
import storesSlice from '../Components/Slices/Store.slice';
import userSlice from "../Components/Slices/User.slice";
import dialogSlice from '../Components/Slices/Dialog.slice';
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    stores: storesSlice,
    userSlice: userSlice,
    dialog: dialogSlice,
  },
  middleware: [thunk],
});
