import {createSlice} from '@reduxjs/toolkit'
import {postRequest, setCookie} from '../../Services/AxiosService';
import {setLoading} from "./Store.slice";

const initialState = {
    user: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            setCookie("access_token",action.payload.token,1);
            state.user = action.payload.user;
        },
        logout: (state, action) => {
            state.user = null;
        },
    }
})

export const login = (_data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const url = '/auth/login'
        const resp = await postRequest(url, _data);
        dispatch(setLoading(false));
        if (resp.status === 200) {
            await dispatch(updateUser({user: resp.data, token: resp.headers['authorization']}));
        }
    } catch (error) {
        console.log(error);
    }
}


export const {updateUser,logout} = userSlice.actions

export default userSlice.reducer
