import React, { useEffect, useState } from 'react'
import '../../CSS/stores.css'
import DialogForm from '../DialogForm'
import {useNavigate} from 'react-router-dom'
import StoresTable from '../Stores/StoresTable'
import { getAllStores,changeActive,changeParent } from '../Slices/Store.slice'
import {useDispatch, useSelector} from 'react-redux'
import { setBooleanParameter,setIsOpen,setMessage,setMode,setStoreIdentifier } from '../Slices/Dialog.slice';
import Loading from '../Loading'


export default function Stores() {
    const dispatch = useDispatch();
    const isLoading = useSelector(state=>state.stores.loading);
    const storeIdentifier = useSelector(state=>state.dialog.storeIdentifier);
    const booleanParameter = useSelector(state=>state.dialog.booleanParameter);
    const mode = useSelector(state=>state.dialog.mode);
    const user = useSelector(state=>state.userSlice.user);
    const nav = useNavigate();

    useEffect(()=>{
        dispatch(getAllStores());
    },[dispatch])
    const onAgree = ()=>{
        if(mode == "active"){
            return dispatch(changeActive(storeIdentifier,booleanParameter));
        }
        if(mode == "parent"){
            return dispatch(changeParent(storeIdentifier,booleanParameter));
        }
    }

    const handleActiveBtn = (identifier,activeMode)=>{
        dispatch(setStoreIdentifier(identifier));
        dispatch(setBooleanParameter(activeMode));
        dispatch(setMode("active"));
        if(activeMode == true){
            dispatch(setMessage("Are you sure you want to make the store active?"));
        }else{
            dispatch(setMessage("Are you sure you want to deactivate?"));
        }
        dispatch(setIsOpen(true));
    }

    const handleParentBtn = (identifier,parentMode)=>{
        dispatch(setStoreIdentifier(identifier));
        dispatch(setBooleanParameter(parentMode));
        dispatch(setMode("parent"));
        dispatch(setMessage("Are you sure you want to change the parent display?"));
        dispatch(setIsOpen(true));
    }

    return (
    <div className='overflow-hidden'>
        <DialogForm onAgree={onAgree}/>
        {!isLoading ?
        <>
        <button className='border-4 rounded-lg border-blue-300 m-7 p-3 hover:bg-blue-300' onClick={()=>nav("/newStore")}>Create new store</button>
       <StoresTable handleActiveBtn={handleActiveBtn} handleParentBtn={handleParentBtn}/>
        </>
            : <Loading/>}
    </div>
    )
}
