import { createSlice } from '@reduxjs/toolkit'
import { getRequest, postRequest } from '../../Services/AxiosService';
import { setIsOpen } from './Dialog.slice';
import {logout} from "./User.slice";

const initialState = {
  stores: [],
  loading: false,
}

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
  updateStores:(state,action)=>{
    state.stores = action.payload;
  },
  setLoading:(state,action)=>{
    state.loading = action.payload;
  },
  }
})

export const getAllStores = ()=>async(dispatch)=>{
    try {
      dispatch(setLoading(true));
      const url = '/store/stores';
      const data = await getRequest(url);
      dispatch(updateStores(data));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
    }
}
export const changeActive = (identifier,activeMode)=>async(dispatch)=>{
    try {
      const url = `/store/updateActive/${identifier}/${activeMode}`
      const resp = await postRequest(url);
      if(resp.status == 200){
        dispatch(getAllStores());
          dispatch(setIsOpen(false));
      }
    } catch (error) {
        console.log(error);
    }
}
export const changeParent = (identifier,parentMode)=>async(dispatch)=>{
  try{
  const url = `/store/updateParent/${identifier}/${parentMode}`
  const resp = await postRequest(url);
  if(resp.status == 200){
    dispatch(getAllStores());
      dispatch(setIsOpen(false));
  }
    } catch (error) {
        console.log(error);
    }
}

export const registerStore = (_data) => async(dispatch)=>{
  try {
    dispatch(setLoading(true));
    const url = '/auth/registerStore'
    const resp = await postRequest(url,_data);
    dispatch(setLoading(false));
    if(resp.status == 200){
      dispatch(getAllStores());
    }else{
      dispatch(logout());
    }
  } catch (error) {
    console.log(error);
  }
}



export const { updateStores,setLoading } = storesSlice.actions

export default storesSlice.reducer
