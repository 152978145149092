import React from 'react'
import {useForm} from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { registerStore } from '../Slices/Store.slice';
import {useDispatch} from 'react-redux'

export default function NewStoreForm() {
const{register , handleSubmit , formState: { errors } } = useForm();
const dispatch = useDispatch();
const nav = useNavigate();

const onSubmit = async(_data) =>{
dispatch(registerStore(_data));
nav("/");
}

  return (
    <>
    <button onClick={()=>nav("/")} className='m-10 border border-slate-300 p-3 rounded-md hover:bg-slate-300'>BACK</button>
    <h2 className='text-3xl text-center my-12'>Create New Store</h2>
    <div className='flex justify-center'>
<form onSubmit={handleSubmit(onSubmit)} >
<input placeholder='Store Name' {...register("name",{required:true})} className="border border-slate-300 p-4 mt-3 rounded-md block w-96" type="text" />
{errors.name && <div className="text-red-600">* Enter store name</div>}

<input placeholder='Consumer Key' {...register("consumerKey",{required:true})} className="border border-slate-300 p-4 mt-3 rounded-md block w-96" type="text" />
{errors.consumerKey && <div className="text-red-600">* Enter consumer key</div>}

<input placeholder='Consumer Secret' {...register("consumerSecret",{required:true})} className="border border-slate-300 p-4 mt-3 rounded-md block w-96" type="text" />
{errors.consumerSecret && <div className="text-red-600">* Enter consumer secret</div>}

<input placeholder='Base Url' {...register("baseUrl",{required:true})} className="border border-slate-300 p-4 mt-3 rounded-md block w-96" type="text" />
{errors.baseUrl && <div className="text-red-600">* Enter base url</div>}
<button className='border-2 rounded-md border-green-600 py-2 px-4 mt-6 mx-auto flex hover:bg-green-600 hover:text-white'>Create Store</button>
</form>
    </div>
    </>
  )
}
