import React from 'react';
import './index.css';
import './App.css';
import AppRoutes from './AppRoutes';
import {Provider} from 'react-redux'
import { store } from './App/Store';

function App() {
  return (
   <Provider store={store}>
    <AppRoutes/>
   </Provider>
  );
}

export default App;
