import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';
import {useSelector,useDispatch} from 'react-redux'
import { setIsOpen } from './Slices/Dialog.slice';


export default function DialogForm({onAgree}) {
const open = useSelector(state=>state.dialog.isOpen);
const message = useSelector(state=>state.dialog.message);
const dispatch = useDispatch();

    const handleClose = ()=>{
        dispatch(setIsOpen(false));
    }
    const handleAgree = ()=>{
        onAgree && onAgree();
        handleClose();
    }

  return (
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgree} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        )
}
