import React from 'react'
import {BASE_URL} from "../../Services/AxiosService";

export default function StoreRow({item,i,handleActiveBtn,handleParentBtn}) {
  const capitalizedItemName = item.name.charAt(0).toUpperCase() + item.name.slice(1);

  return (
    <tr key={item.id} className='hover:bg-slate-100'>
                    <td>{i+1}</td>
                    <td className='fixed'>{capitalizedItemName}</td>
                    <td><a href={item.baseUrl} target='_blank'>{item.baseUrl}</a></td>
                    <td><a href={BASE_URL+'/zap/'+item.identifier} target='_blank'>{item.identifier}</a></td>
                    <td><button onClick={()=>handleParentBtn(item.identifier,!item.showAllParentsCategories)} className='update-btn'>{item.showAllParentsCategories ? "true":"false"}</button></td>
                    <td>{item.createdOn.substring(0,10)}</td>
                    <td><button onClick={()=>handleActiveBtn(item.identifier,!item.active)} className='update-btn'>{item.active ? "Active":"Deactive"}</button></td>
                </tr>
  )
}
