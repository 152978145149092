import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message:"",
  storeIdentifier:"",
  booleanParameter:false,
  mode:"",
  isOpen:false,
}

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
  setMessage:(state,action)=>{
    state.message = action.payload;
  },
  setStoreIdentifier:(state,action)=>{
    state.storeIdentifier = action.payload;
  },
  setBooleanParameter:(state,action)=>{
    state.booleanParameter = action.payload;
  },
  setMode:(state,action)=>{
    state.mode = action.payload;
  },
  setIsOpen:(state,action)=>{
    state.isOpen = action.payload;
  },
  }
})


export const { setBooleanParameter,setIsOpen,setMessage,setMode,setStoreIdentifier } = dialogSlice.actions

export default dialogSlice.reducer