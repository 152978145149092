import React from 'react'
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import Stores from './Components/Stores/Stores'
import NewStoreForm from './Components/Stores/NewStoreForm'
import Login from "./Components/Login/Login";

export default function AppRoutes() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element={<Stores/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/newStore' element={<NewStoreForm/>}/>
    </Routes>
    </BrowserRouter>
  )
}
